// src/components/CompanyData.js
import React, { useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CompanyData.css";

function CompanyData({ company, role, onSave }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({
    company_name: company.company_name,
    company_description: company.company_description,
    company_logo_url: company.company_logo_url,
  });

  const handleEditToggle = () => setIsEditMode(!isEditMode);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = () => {
    onSave(formData); // Pass data to a save function in the parent component
    setIsEditMode(false);
  };

  return (
    <Card className="company-card w-100">
      <Card.Body className="company-header">
        <Link to={`/company/${company.company_id}`} className="company-link">
          <div
            className="company-logo-placeholder"
            style={{
              backgroundImage: formData.company_logo_url
                ? `url(${formData.company_logo_url})`
                : "none",
            }}
          >
            {!formData.company_logo_url && <span>No Logo</span>}
          </div>
        </Link>
        <div className="company-header-text">
          {isEditMode ? (
            <>
              <Form.Control
                type="text"
                name="company_name"
                value={formData.company_name}
                onChange={handleChange}
                className="mb-2"
              />
              <Form.Control
                as="textarea"
                name="company_description"
                value={formData.company_description}
                onChange={handleChange}
                className="company-description-input"
              />
            </>
          ) : (
            <>
              <Link
                to={`/company/${company.company_id}`}
                className="company-link"
              >
                <Card.Title>{company.company_name}</Card.Title>
              </Link>
              <Card.Text className="company-description">
                {company.company_description}
              </Card.Text>
            </>
          )}
        </div>
        {role === "admin" || role === "owner" ? (
          !isEditMode ? (
            <Button
              variant="link"
              onClick={handleEditToggle}
              className="edit-company-link"
            >
              Edit Company
            </Button>
          ) : (
            <div className="action-buttons mt-3">
              <Button variant="success" onClick={handleSave} className="me-2">
                Save
              </Button>
              <Button variant="secondary" onClick={handleEditToggle}>
                Cancel
              </Button>
            </div>
          )
        ) : null}
      </Card.Body>
    </Card>
  );
}

export default CompanyData;
