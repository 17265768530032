// src/components/Footer.js
import React from "react";
import { Container, Navbar } from "react-bootstrap";

function Footer() {
  return (
    <Navbar bg="light" variant="light" className="footer">
      <Container className="justify-content-center">
        <Navbar.Text>
          &copy; {new Date().getFullYear()} Strategic Nerds, Inc. All rights reserved.
        </Navbar.Text>
      </Container>
    </Navbar>
  );
}

export default Footer;
