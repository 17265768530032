// src/components/JobListing.js
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import "./JobListing.css";

function JobListing({ loggedInPersonId }) {
  const { joblistingId } = useParams();
  const [job, setJob] = useState(null);
  const [company, setCompany] = useState(null);
  const [hiringManager, setHiringManager] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const token = localStorage.getItem("token");

        const jobResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/joblisting/${joblistingId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (jobResponse.ok) {
          const jobData = await jobResponse.json();
          setJob(jobData);

          if (jobData.company_id) {
            const companyResponse = await fetch(
              `${process.env.REACT_APP_API_URL}/company/${jobData.company_id}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );

            if (companyResponse.ok) {
              const companyData = await companyResponse.json();
              setCompany(companyData);

              const hasAdminAccess = companyData.slack_token !== undefined;
              setIsAdmin(hasAdminAccess);

              if (hasAdminAccess && jobData.hiring_manager) {
                const hiringManagerResponse = await fetch(
                  `${process.env.REACT_APP_API_URL}/person/${jobData.hiring_manager}`,
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  }
                );

                if (hiringManagerResponse.ok) {
                  const hiringManagerData = await hiringManagerResponse.json();
                  setHiringManager(hiringManagerData);
                }
              }
            }
          } else {
            console.error("Company ID is undefined in job data");
          }
        } else {
          console.error("Failed to fetch job data");
        }
      } catch (error) {
        console.error("Error fetching job data:", error);
      }
    };

    fetchJobData();
  }, [joblistingId, loggedInPersonId]);

  if (!job || !company) {
    return <div>Loading...</div>;
  }

  return (
    <Container fluid className="job-listing-container">
      <Row className="justify-content-center">
        <Col xl={8} lg={9} md={10}>
          <Card className="job-card">
            <Card.Body>
              <Card.Title className="job-title">{job.title}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted company-name">
                {company.company_name}
              </Card.Subtitle>
              <Card.Text className="job-meta">
                {job.location}, {job.type}
              </Card.Text>
              {isAdmin && hiringManager && (
                <Card.Text className="hiring-manager">
                  Hiring Manager: {hiringManager.first_name}{" "}
                  {hiringManager.last_name}
                </Card.Text>
              )}
              <div className="job-description">
                <ReactMarkdown>{job.description}</ReactMarkdown>
              </div>
              {isAdmin && (
                <div className="edit-job-button-container">
                  <Link
                    to={`/company/${company.company_id}/joblisting/new`}
                    state={{ jobListing: job }}
                  >
                    <Button variant="primary">Edit Job</Button>
                  </Link>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default JobListing;
