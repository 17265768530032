// src/components/CreateAccount.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Card, Container, Row, Col } from "react-bootstrap";
import Alert from "./Alert";
import "./CreateAccount.css";

function CreateAccount() {
  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [alertMessage, setAlertMessage] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setEmailError("");
    setPasswordError("");

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordPattern.test(password)) {
      setPasswordError(
        "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/create-account`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            companyName,
            firstName,
            lastName,
            email,
            password,
          }),
        }
      );

      if (response.ok) {
        setAlertMessage({
          message: "Account created successfully!",
          type: "success",
        });
      } else {
        const data = await response.json();
        setAlertMessage({ message: "Error creating account", type: "error" });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setAlertMessage({
        message: "An error occurred, please try again later.",
        type: "error",
      });
    }
  };

  return (
    <Container fluid className="create-account-container">
      {alertMessage && (
        <Alert message={alertMessage.message} type={alertMessage.type} />
      )}
      <Row className="justify-content-center">
        <Col xs={12} md={6} lg={4}>
          <Card className="p-4">
            <Card.Body>
              <h1 className="mb-4 text-center">Create a New Account</h1>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="companyName" className="mb-3">
                  <Form.Label>Company Name:</Form.Label>
                  <Form.Control
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="firstName" className="mb-3">
                  <Form.Label>First Name:</Form.Label>
                  <Form.Control
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="lastName" className="mb-3">
                  <Form.Label>Last Name:</Form.Label>
                  <Form.Control
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="email" className="mb-3">
                  <Form.Label>Email:</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    isInvalid={!!emailError}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {emailError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="password" className="mb-3">
                  <Form.Label>Password:</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    isInvalid={!!passwordError}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {passwordError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button variant="success" type="submit" className="w-100 mb-3">
                  Create Free Account
                </Button>
              </Form>
              <hr />
              <Button variant="outline-secondary" className="w-100 mt-3">
                Sign in With Google
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default CreateAccount;
