// src/components/PersonalApplications.js
import React from "react";
import "./PersonalApplications.css";

function PersonalApplications() {
  return (
    <div className="personal-applications-container">
      <h1>Your Applications</h1>
    </div>
  );
}

export default PersonalApplications;
