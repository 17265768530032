// src/components/NewJobListing.js
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import "./NewJobListing.css";

function NewJobListing({ loggedInPersonId }) {
  const { state } = useLocation();
  const jobListing = state?.jobListing || {};

  const [title, setTitle] = useState(jobListing.title || "");
  const [description, setDescription] = useState(jobListing.description || "");
  const [location, setLocation] = useState(jobListing.location || "");
  const [type, setType] = useState(jobListing.type || "remote");
  const [selectedCompany, setSelectedCompany] = useState(
    jobListing.company_id || ""
  );
  const [selectedHiringManager, setSelectedHiringManager] = useState(
    jobListing.hiring_manager_id || ""
  );

  const [companies, setCompanies] = useState([]);
  const [hiringManagers, setHiringManagers] = useState([]);
  const navigate = useNavigate();
  const mdParser = new MarkdownIt();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/person/${loggedInPersonId}/companies`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.ok) {
          const data = await response.json();
          setCompanies(
            data.filter(
              (company) => company.role === "admin" || company.role === "owner"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    fetchCompanies();
  }, [loggedInPersonId]);

  useEffect(() => {
    const fetchHiringManagers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (selectedCompany) {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/company/${selectedCompany}/admins`,
            { headers: { Authorization: `Bearer ${token}` } }
          );

          if (response.ok) {
            const data = await response.json();
            setHiringManagers(data);
          }
        }
      } catch (error) {
        console.error("Error fetching hiring managers:", error);
      }
    };
    fetchHiringManagers();
  }, [selectedCompany]);

  const handleEditorChange = ({ text }) => setDescription(text);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const apiUrl = jobListing.job_listing_id
      ? `${process.env.REACT_APP_API_URL}/joblisting/${jobListing.job_listing_id}`
      : `${process.env.REACT_APP_API_URL}/joblisting`;
    const method = jobListing.job_listing_id ? "PUT" : "POST";

    try {
      const response = await fetch(apiUrl, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title,
          description,
          location,
          type,
          company_id: selectedCompany,
          hiring_manager: selectedHiringManager,
        }),
      });

      if (response.ok) {
        navigate(`/company/${selectedCompany}`);
      } else {
        console.error("Failed to submit job listing");
      }
    } catch (error) {
      console.error("Error submitting job listing:", error);
    }
  };

  return (
    <Container className="new-job-listing-container">
      <h1 className="text-center my-4">
        {jobListing.job_listing_id
          ? "Edit Job Listing"
          : "Create New Job Listing"}
      </h1>
      <Form onSubmit={handleSubmit} className="new-job-form">
        <Form.Group controlId="title">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="description" className="mt-3">
          <Form.Label>Description</Form.Label>
          <MdEditor
            value={description}
            style={{ height: "300px" }}
            renderHTML={(text) => mdParser.render(text)}
            onChange={handleEditorChange}
          />
        </Form.Group>

        <Row className="mt-3">
          <Col md={6}>
            <Form.Group controlId="location">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="type">
              <Form.Label>Type</Form.Label>
              <Form.Select
                value={type}
                onChange={(e) => setType(e.target.value)}
                required
              >
                <option value="remote">Remote</option>
                <option value="hybrid">Hybrid</option>
                <option value="office">Office</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="company" className="mt-3">
          <Form.Label>Company</Form.Label>
          <Form.Select
            value={selectedCompany}
            onChange={(e) => setSelectedCompany(e.target.value)}
            required
          >
            <option value="">Select a Company</option>
            {companies.map((company) => (
              <option key={company.company_id} value={company.company_id}>
                {company.company_name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="hiringManager" className="mt-3">
          <Form.Label>Hiring Manager</Form.Label>
          <Form.Select
            value={selectedHiringManager}
            onChange={(e) => setSelectedHiringManager(e.target.value)}
            required
          >
            <option value="">Select a Hiring Manager</option>
            {hiringManagers.map((manager) => (
              <option key={manager.person_id} value={manager.person_id}>
                {manager.first_name} {manager.last_name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Button variant="primary" type="submit" className="w-100 mt-4">
          {jobListing.job_listing_id
            ? "Update Job Listing"
            : "Create Job Listing"}
        </Button>
      </Form>
    </Container>
  );
}

export default NewJobListing;
