// src/components/PersonalData.js
import React from "react";
import { Card, Form, Button, Col } from "react-bootstrap";
import "./PersonalData.css";

function PersonalData({
  person,
  formData,
  setFormData,
  avatarFile,
  setAvatarFile,
  resumeFile,
  setResumeFile,
  isEditMode,
  handleEditToggle,
  handleSave,
  loggedInPersonId,
}) {

  // Return early if person data has not loaded
  if (!person) {
    return <div>Loading...</div>; // Placeholder or loading spinner
  }

  console.log("Logged in person: " + loggedInPersonId);

  const handleChange = (e) => {
    const { name, type, checked, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : files ? files[0] : value,
    });

    if (name === "avatar") {
      setAvatarFile(files[0]);
    } else if (name === "resume") {
      setResumeFile(files[0]);
    }
  };

  return (
    <Card className="personal-data w-100">
      <Card.Body className="text-start">
        <div className="person-header d-flex align-items-center mb-4">
          <div
            className="image-placeholder"
            style={{
              backgroundImage: person.avatar_url
                ? `url(${person.avatar_url})`
                : "none",
              backgroundSize: "cover",
              backgroundPosition: "center",
              cursor: isEditMode ? "pointer" : "default",
            }}
          >
            {!person.avatar_url && <span>No Avatar</span>}
          </div>
          <div className="header-text">
            <h1 className="d-flex align-items-center">
              {isEditMode ? (
                <>
                  <Form.Control
                    type="text"
                    name="first_name"
                    value={formData.first_name || ""}
                    onChange={handleChange}
                    className="me-2"
                  />
                  <Form.Control
                    type="text"
                    name="last_name"
                    value={formData.last_name || ""}
                    onChange={handleChange}
                  />
                </>
              ) : (
                `${person.first_name} ${person.last_name}`
              )}
              {String(person.person_id) === loggedInPersonId && !isEditMode && (
                <Button
                  variant="link"
                  className="edit-profile ms-2"
                  onClick={handleEditToggle}
                >
                  Edit Profile
                </Button>
              )}
            </h1>
            <h2 className="email">{person.email}</h2>
          </div>
        </div>

        <div className="person-details">
          {(person.title || isEditMode) && (
            <h3 className="title">
              {isEditMode ? (
                <Form.Control
                  type="text"
                  name="title"
                  value={formData.title || ""}
                  onChange={handleChange}
                  className="input-field"
                />
              ) : (
                person.title
              )}
            </h3>
          )}
          {(person.description || isEditMode) && (
            <p className="description">
              {isEditMode ? (
                <Form.Control
                  as="textarea"
                  name="description"
                  value={formData.description || ""}
                  onChange={handleChange}
                  className="description-input-field"
                />
              ) : (
                person.description
              )}
            </p>
          )}
          <Form.Group className="checkbox-group mb-3">
            <Form.Label>Actively looking for a new role?</Form.Label>
            {isEditMode ? (
              <Form.Check
                type="switch"
                name="actively_looking"
                checked={formData.actively_looking || false}
                onChange={handleChange}
                className="toggle-switch"
              />
            ) : (
              <span>{person.actively_looking ? "Yes" : "No"}</span>
            )}
          </Form.Group>

          <Form.Group className="checkbox-group mb-3">
            <Form.Label>Willing to relocate?</Form.Label>
            {isEditMode ? (
              <Form.Check
                type="switch"
                name="willing_to_relocate"
                checked={formData.willing_to_relocate || false}
                onChange={handleChange}
                className="toggle-switch"
              />
            ) : (
              <span>{person.willing_to_relocate ? "Yes" : "No"}</span>
            )}
          </Form.Group>

          <div className="online-profiles">
            {isEditMode ? (
              <>
                <Form.Control
                  type="text"
                  name="linkedin_profile"
                  placeholder="LinkedIn Profile URL"
                  value={formData.linkedin_profile || ""}
                  onChange={handleChange}
                  className="mb-2"
                />
                <Form.Control
                  type="text"
                  name="github_profile"
                  placeholder="GitHub Profile URL"
                  value={formData.github_profile || ""}
                  onChange={handleChange}
                  className="mb-2"
                />
              </>
            ) : (
              <>
                {person.linkedin_profile && (
                  <a
                    href={person.linkedin_profile}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="profile-link"
                  >
                    LinkedIn Profile
                  </a>
                )}
                {person.github_profile && (
                  <a
                    href={person.github_profile}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="profile-link"
                  >
                    GitHub Profile
                  </a>
                )}
              </>
            )}
          </div>

          <div className="resume-section mt-4">
            <h5>Resume</h5>
            {person.resume_url ? (
              !isEditMode ? (
                <a href={person.resume_url} download className="resume-link">
                  Download Resume
                </a>
              ) : (
                <Form.Group controlId="resumeUpload" className="mt-2">
                  <Form.Label>Update Resume</Form.Label>
                  <Form.Control
                    type="file"
                    name="resume"
                    onChange={handleChange}
                  />
                </Form.Group>
              )
            ) : (
              <Form.Group controlId="resumeUpload" className="mt-2">
                <Form.Label>Upload Resume</Form.Label>
                <Form.Control
                  type="file"
                  name="resume"
                  onChange={handleChange}
                />
              </Form.Group>
            )}
          </div>

          {isEditMode && (
            <div className="action-buttons mt-4">
              <Button variant="success" onClick={handleSave} className="me-2">
                Save
              </Button>
              <Button variant="secondary" onClick={handleEditToggle}>
                Cancel
              </Button>
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

export default PersonalData;
