// src/components/Login.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Card, Container, Row, Col } from "react-bootstrap";
import Alert from "./Alert";
import "./Login.css";

function Login({ setIsLoggedIn }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertMessage, setAlertMessage] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setAlertMessage(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("token", data.token);
        localStorage.setItem("person_id", data.person_id);
        setIsLoggedIn(true);
        navigate(`/person/${data.person_id}`);
      } else {
        setAlertMessage({
          message: "Error logging in, please try again.",
          type: "error",
        });
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setAlertMessage({
        message: "An error occurred, please try again later.",
        type: "error",
      });
    }
  };

  const handleGoogleSignIn = () => {
    // Implement Google Sign-In logic here
  };

  return (
    <Container fluid className="login-container">
      {alertMessage && (
        <Alert message={alertMessage.message} type={alertMessage.type} />
      )}
      <Row className="justify-content-center">
        <Col xs={12} md={6} lg={4}>
          <Card className="p-4">
            <Card.Body>
              <h1 className="mb-4 text-center">Login</h1>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label>Email:</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formPassword" className="mb-3">
                  <Form.Label>Password:</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button variant="dark" type="submit" className="w-100 mb-3">
                  Login
                </Button>
              </Form>
              <div className="text-center">
                <hr />
                <Button
                  variant="outline-secondary"
                  className="w-100 mt-3"
                  onClick={handleGoogleSignIn}
                >
                  Sign in with Google
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
