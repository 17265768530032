// src/components/Person.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import PersonalData from "./PersonalData";
import CompanyData from "./CompanyData";
import PersonalApplications from "./PersonalApplications";
import "./Person.css";

function Person() {
  const { id } = useParams();
  const [person, setPerson] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [avatarFile, setAvatarFile] = useState(null);
  const [resumeFile, setResumeFile] = useState(null);

  // Retrieve logged-in person ID from localStorage
  const loggedInPersonId = localStorage.getItem("person_id");

  useEffect(() => {
    const fetchPersonData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, redirecting to login");
          return;
        }

        const personResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/person/${id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (personResponse.ok) {
          const data = await personResponse.json();
          setPerson(data);
          setFormData(data);
        } else {
          console.error("Failed to fetch person data");
        }

        const companiesResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/person/${id}/companies`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (companiesResponse.ok) {
          const companiesData = await companiesResponse.json();
          setCompanies(companiesData);
        } else {
          console.error("Failed to fetch company data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPersonData();
  }, [id]);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col lg={8}>
          <PersonalData
            person={person}
            formData={formData}
            setFormData={setFormData}
            avatarFile={avatarFile}
            setAvatarFile={setAvatarFile}
            resumeFile={resumeFile}
            setResumeFile={setResumeFile}
            isEditMode={isEditMode}
            handleEditToggle={() => setIsEditMode(!isEditMode)}
            handleSave={() => console.log("Save functionality")}
            loggedInPersonId={loggedInPersonId} // Pass loggedInPersonId here
          />
        </Col>
        <Col lg={4}>
          <PersonalApplications />
        </Col>
      </Row>
      <Row>
        <h1 className="text-center mt-4 mb-4">Your Companies</h1>
        {companies.map((company) => (
          <Row key={company.company_id} className="mb-4">
            <Col xs={12}>
              <CompanyData company={company} role={company.role} />
            </Col>
          </Row>
        ))}
      </Row>
    </Container>
  );
}

export default Person;
