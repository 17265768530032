// src/components/MainPage.js
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./MainPage.css";

function MainPage({ isLoggedIn }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/person/:id");
    }
  }, [isLoggedIn, navigate]);

  return (
    <main className="main-page">
      <Container>
        {/* Hero Section */}
        <Row className="align-items-center mb-5 hero">
          <Col lg={6}>
            <div className="hero-image">
              <img
                src="/images/hero-image.png"
                alt="Hero"
                className="img-fluid"
              />
            </div>
          </Col>
          <Col lg={6}>
            <h1 className="hero-title">Interview Management for Startups</h1>
            <h2 className="hero-subtitle">
              Easy to use, affordable, and powerful.
            </h2>
            <Link to="/create-account">
              <Button variant="success" className="mt-3 cta-button">
                Try for Free
              </Button>
            </Link>
          </Col>
        </Row>

        {/* Blocks Section */}
        <Row className="justify-content-between text-center block">
          <Col lg={4} className="block-item">
            <img
              src="/images/move-fast.png"
              alt="Get started"
              className="block-icon"
            />
            <h1 className="block-title">Fast</h1>
            <h2 className="block-text">
              Time is of the essence. Set up your company, write your job
              descriptions, publish them, and be ready to receive applicants quickly.
            </h2>
          </Col>

          <Col lg={4} className="block-item">
            <img
              src="/images/easy-to-use.png"
              alt="Easy to use"
              className="block-icon"
            />
            <h1 className="block-title">Easy</h1>
            <h2 className="block-text">
              Use Markdown to edit your job descriptions. Use free APIs to
              integrate jobs into your own website. Provide candidates
              feedback easily.
            </h2>
          </Col>

          <Col lg={4} className="block-item">
            <img
              src="/images/automate.png"
              alt="Automate coordination"
              className="block-icon"
            />
            <h1 className="block-title">Automated</h1>
            <h2 className="block-text">
              Set up your hiring plan and notify candidates to schedule their
              interviews automatically. Tight integration with Calendly to
              streamline scheduling.
            </h2>
          </Col>
        </Row>
      </Container>
    </main>
  );
}

export default MainPage;
