// src/components/Header.js
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav, Button, Container } from "react-bootstrap";

function Header({ isLoggedIn }) {
  const navigate = useNavigate();
  const personId = localStorage.getItem("person_id"); // Retrieve person_id from localStorage

  const handleLogout = () => {
    localStorage.removeItem("token"); // Remove the token from localStorage
    localStorage.removeItem("person_id"); // Remove the person_id from localStorage
    navigate("/"); // Redirect to the homepage after logging out
    window.location.reload(); // Force a full page reload to reset the header
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand
          as={Link}
          to={isLoggedIn ? `/person/${personId}` : "/"}
          className="mx-auto"
          style={{ fontWeight: "bold" }}
        >
          Startup Roles
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link as={Link} to="/">
              Search Jobs
            </Nav.Link>
            {isLoggedIn ? (
              <>
                <Nav.Link as={Link} to={`/person/${personId}`}>
                  Profile
                </Nav.Link>
                <Button variant="outline-danger" onClick={handleLogout}>
                  Logout
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="outline-dark"
                  as={Link}
                  to="/login"
                  className="me-2"
                >
                  Login
                </Button>
                <Button variant="success" as={Link} to="/create-account">
                  Create Account
                </Button>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
