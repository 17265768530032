// src/App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import MainPage from "./components/MainPage";
import Login from "./components/Login";
import Person from "./components/Person";
import CreateAccount from "./components/CreateAccount";
import NewJobListing from "./components/NewJobListing";
import JobListing from "./components/JobListing";
import Company from "./components/Company"; // Import the Company component

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("token"));
  const loggedInPersonId = localStorage.getItem("person_id");

  // Update login state when the token changes
  useEffect(() => {
    setIsLoggedIn(!!localStorage.getItem("token"));
  }, []);

  return (
    <BrowserRouter>
      <Header isLoggedIn={isLoggedIn} />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route
          path="/login"
          element={<Login setIsLoggedIn={setIsLoggedIn} />}
        />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/person/:id" element={<Person />} />
        <Route
          path="/company/:companyId"
          element={<Company loggedInPersonId={loggedInPersonId} />}
        />
        <Route
          path="/joblisting/:joblistingId"
          element={<JobListing loggedInPersonId={loggedInPersonId} />}
        />
        <Route
          path="/company/:companyId/joblisting/new"
          element={<NewJobListing loggedInPersonId={loggedInPersonId} />}
        />
        {/* New route for Company */}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
