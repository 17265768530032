import React, { useState, useEffect } from "react";
import { Alert as BootstrapAlert } from "react-bootstrap";

function Alert({ message, type = "danger" }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setIsVisible(true);
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 1250);

      return () => clearTimeout(timer);
    }
  }, [message, type]);

  if (!isVisible) return null; // Don't render if not visible

  return (
    <BootstrapAlert
      variant={type}
      className="position-fixed top-0 start-50 translate-middle-x"
    >
      {message}
    </BootstrapAlert>
  );
}

export default Alert;
