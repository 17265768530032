// src/components/Company.js
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Container, Table, Button, Row, Col } from "react-bootstrap";
import CompanyData from "./CompanyData";

function Company({ loggedInPersonId }) {
  const { companyId } = useParams();
  const [company, setCompany] = useState(null);
  const [jobListings, setJobListings] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const token = localStorage.getItem("token");
        const companyResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/company/${companyId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (companyResponse.ok) {
          const data = await companyResponse.json();
          setCompany(data);
          setIsAdmin(data.role === "admin" || data.role === "owner"); // Adjust this check as needed
        } else {
          console.error("Failed to fetch company data");
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    const fetchJobListings = async () => {
      try {
        const token = localStorage.getItem("token");
        const jobResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/company/${companyId}/joblistings`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (jobResponse.ok) {
          const jobData = await jobResponse.json();
          setJobListings(jobData);
        } else {
          console.error("Failed to fetch job listings");
        }
      } catch (error) {
        console.error("Error fetching job listings:", error);
      }
    };

    fetchCompanyData();
    fetchJobListings();
  }, [companyId, loggedInPersonId]);

  const handleSave = async (updatedCompanyData) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/company/${companyId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedCompanyData),
        }
      );

      if (response.ok) {
        const updatedCompany = await response.json();
        setCompany(updatedCompany);
        setIsAdmin(
          updatedCompany.role === "admin" || updatedCompany.role === "owner"
        );
      } else {
        console.error("Failed to save company data");
      }
    } catch (error) {
      console.error("Error saving company data:", error);
    }
  };

  if (!company) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="company-container">
      <CompanyData
        company={company}
        role={isAdmin ? "admin" : "user"}
        onSave={handleSave}
        lg={12}
      />
      <h1 className="job-listings-header">
        Open Job Listings for {company.company_name}
      </h1>
      <Table striped bordered hover responsive className="job-listings-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Location</th>
            <th>Type</th>
            {isAdmin && (
              <>
                <th>Hiring Manager</th>
                <th>Edit</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {jobListings.map((job) => (
            <tr key={job.job_listing_id}>
              <td>
                <Link to={`/joblisting/${job.job_listing_id}`}>
                  {job.title}
                </Link>
              </td>
              <td>
                {job.description.length > 200
                  ? `${job.description.slice(0, 200)}...`
                  : job.description}
              </td>
              <td>{job.location}</td>
              <td>{job.type}</td>
              {isAdmin && (
                <>
                  <td>
                    {job.hiring_manager_first_name}{" "}
                    {job.hiring_manager_last_name}
                  </td>
                  <td>
                    <Link
                      to={`/company/${job.company_id}/joblisting/new`}
                      state={{ jobListing: job }}
                    >
                      Edit Job
                    </Link>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </Table>

      {isAdmin && (
        <Row className="justify-content-end">
          <Col xs="auto">
            <Button
              as={Link}
              to={`/company/${companyId}/joblisting/new`}
              variant="success"
              className="add-job-button"
            >
              Add New Job Listing
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default Company;
